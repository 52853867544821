@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(~@/assets/fonts/NotoSansKR-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(~@/assets/fonts/NotoSansKR-Bold.woff2) format('woff2');
}