@charset "UTF-8";

@mixin after() {
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}
@mixin word-break() {
  word-break: break-all;
  word-break: break-word;
}

button{
  cursor: pointer;
}
table{
  th, td{
    @include word-break();
  }
}
label{
  cursor: pointer;
}
.dp-none{
  display: none !important;
}
.btn-box{
  display: flex;
  flex-wrap: wrap;
  &.type-center{
    justify-content: center;
  }
  &.type-large{
    gap: 16px;
  }
  &.type-modal{
    margin-top: 48px;
  }
  &.type-login{
    margin-top: 48px;
  }
}
.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.type-small{
    min-width: 88px;
    min-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
  }
  &.type-large{
    min-width: 236px;
    min-height: 64px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
  }
  &.type-wide{
    min-width: 320px;
    min-height: 64px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
  }
  &.type-apply{
    background: #2d91df;
    color: $C-white;
  }
  &.type-cancel{
    border: 1px solid #c4c4c4;
    background: $C-white;
    color: $C-gray-700;
  }
  &.type-back{
    background: $C-gray-500;
    color: $C-white;
  }
}
.badge{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.type-small{
    min-width: 88px;
    min-height: 32px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
  }
  &.type-apply{
    background: $C-active-200;
    color: $C-white;
  }
  &.type-warning{
    border: 1px solid #ff9407;
    background: $C-white;
    color: #ff9407;
  }
  &.type-cancel{
    border: 1px solid #c4c4c4;
    background: $C-white;
    color: $C-gray-700;
  }
}
.text{
  &.type-on{
    color: #4f99d7;
  }
  &.type-left{
    text-align: left;
  }
  &.type-join{
    color: $C-gray-400;
  }
  &.type-warning{
    color: $C-warning;
  }
}
.opt{
  height: 32px;
  padding: 0 32px 0 15px;
  border: 1px solid $C-gray-100;
  border-radius: 5px;
  background: $C-white url(~@/assets/images/ico_arrow.svg) no-repeat calc(100% - 12px) 50%;
}
.tf{
  height: 32px;
  padding: 0 15px;
  border: 1px solid $C-gray-100;
  border-radius: 5px;
  background: $C-white;
  &.type-large{
    width: 352px;
  }
  &.type-tel{
    width: 58px;
  }
  &.type-wide{
    width: 100%;
  }
  &.type-area{
    overflow: auto;
    height: 100%;
    padding-top: .5em;
    padding-bottom: .5em;
  }
  &.type-content{
    width: 100%;
  }
  &.type-survey{
    width: 100%;
    height: 144px;
  }
}
.inp{
  flex: none;
  position: relative;
  appearance: none;
  &.type-radio{
    width: 20px;
    height: 20px;
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid $C-gray-300;
      border-radius: 5px;
      background: $C-white;
    }
    &:checked{
      &:before{
        border-color: $C-active-200;
      }
      &:after{
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        background: $C-active-200;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.type-hidden{
    appearance: none;
    position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    background: none;
    opacity: 0;
  }
}