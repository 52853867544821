html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video,input,button,select,textarea,*:before,*:after{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: inherit;
  font-family: inherit;
  font-style: normal;
  color: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-border-radius: 0;
}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
  display: block;
}
html{
  -webkit-text-size-adjust: none;
}
body{
  font-weight: 400;
  line-height: 1;
}
ol,ul{
  list-style: none;
}
blockquote,q{
  quotes: none;
}
table{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
th, td{
  text-align: center;
  vertical-align: middle;
}
input{
  border-radius: 0;
  background: transparent;
  outline: 0;
}
button,select,img,textarea{
  border-radius: 0;
  background: transparent;
  outline: 0;
  -webkit-appearance: none;
}
select::-ms-expand{
  /*display: none;*/
}
a{
  text-decoration: none;
}
button{
  overflow: visible;
  line-height: inherit;
}
img, svg, textarea{
  overflow: hidden;
  max-width: 100%;
  vertical-align: middle;
}
textarea{
  resize: none;
}
input::-ms-clear,
input::-ms-reveal {display: none; width : 0; height: 0;}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration { display: none; }
.react-datepicker-wrapper{
  width: auto;
}
.react-datepicker { font-size: 1em !important }
.react-datepicker-popper {
  z-index: 12 !important;
}
.react-datepicker__header { padding-top: 0.8em !important}
.react-datepicker__month { margin: 0.4em 1em !important}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month { font-size: 1em !important }
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  //border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous { border-right-color: #ccc !important; left: 1em !important }
.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
  left: 220px !important
}


.react-datepicker__time-container { width: 6em !important}
.react-datepicker-time__header { font-size: 1em !important }
.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }
.react-datepicker__time-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width:100% !important
}
.react-datepicker__time-list { padding: 0 !important; }