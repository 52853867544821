@charset "UTF-8";

html, body{
  height: 100%;
}
html{
  font-size: $F-base-size;
}
body{
  font-family: $F-base;
  &.type-modal-on{
    overflow: hidden;
  }
}
#root{
  height: 100%;
}
.loading_indicator {
  position: fixed;
}
.container{
  display: flex;
  min-width: 0;
  max-width: $G-max-width;
  margin: 0 auto;
}
.content{
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background: $C-gray-100;
  &.type-sub{
    flex: 1;
  }
}
.content-inner{
  flex: 1;
  display: flex;
  flex-direction: column;
  width: $G-content-width;
  min-width: 0;
  margin: 0 auto;
  padding: 64px 20px 128px;
  background: inherit;
  box-sizing: content-box;
}
//aside
.aside{
  flex: none;
  position: sticky;
  top: 0;
  height: 100vh;
  &:before{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba($C-black, 0.2);
  }
}
.aside-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: -$G-aside-mobile-height - 6px;
  right: 0;
  z-index: 100;
  min-width: 0;
  height: $G-aside-mobile-height;
  padding: 0 16+16+20px;
  background: $C-white;
  box-shadow: 0 3px 6px rgba($C-black, 0.16);
  transition: all .2s;
}
.mobile-menu-btn{
  position: absolute;
  top: 50%;
  left: 0;
  padding: 16px;
  transform: translateY(-50%);
}
.mobile-menu{}
.mobile-logo-btn{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 4px;
}
.mobile-logo{
  width: 75px;
}
.mobile-logo-summary{
  width: 115px;
}

.aside-content{
  z-index: 102;
  width: $G-aside-width;
  height: 100%;
  padding: 65px 0 65px 44px;
  background: $C-white;
  box-shadow: 1px 0 1px #c4c4c4, -1px 0 1px #c4c4c4;
  @include overlay;
}
.aside-close-btn{
  display: none;
  position: absolute;
  top: 24px;
  right: 25px;
}
.logo-btn{
  display: block;
  width: 201px;
}
.logo{
  display: block;
  width: 100%;
}
.logo-summary{
  display: block;
  width: 100%;
}
.member-type{
  height: 87px;
  padding: 20px 0 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: $C-active-200;
  letter-spacing: -0.45px;
  .user-nm{
    font-size: 16px;
    span{
      color: $C-gray-500;
    }
  }
}
.snb{
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 0 64px;
}
.snb-item{}
.snb-btn{
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $C-gray-200;
  text-align: left;
  transition: all .2s;
  &:hover{
    color: $C-apply;
  }
  &.type-on{
    color: $C-active-400;
  }
}
.snb-ico{
  width: 34px;
  fill: $C-gray-200;
  transition: all .2s;
  .snb-btn:hover &{
    fill: $C-apply;
  }
  .snb-btn.type-on &{
    fill: $C-active-400;
  }
}
.snb-text{
  margin: 0 0 0 21px;
  vertical-align: middle;
}
.snb-sub{
  display: none;
  flex-direction: column;
  gap: 4px;
  margin: 16px 0 0 34+21+8px;
  .snb-item.type-extend &{
    display: flex;
  }
}
.snb-sub-item{
  font-size: 18px;
}
.snb-sub-btn{
  display: block;
  width: 100%;
  font-weight: 700;
  line-height: 27px;
  color: $C-gray-200;
  text-align: left;
  transition: all .2s;
  &:hover{
    color: $C-apply;
  }
  &.type-on{
    color: $C-active-400;
  }
}
.snb-btn-box{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
//footer
.footer{
  background: $C-gray-800;
}
.foot-inner{
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  width: $G-content-width;
  margin: 0 auto;
  padding: 64px 20px;
  background: inherit;
  box-sizing: content-box;
}
.foot-logo-box{
  display: flex;
  align-items: center;
  gap: 12px 14px;
  min-width: 0;
}
.foot-logo{
  flex: none;
  width: 145px;
}
.foot-logo-summary-box{
  display: flex;
  flex-wrap: wrap;
  gap: 7px 5px;
  min-width: 0;
}
.foot-logo-summary-item{
  &.type-1{
    width: 100%;
  }
}
.foot-logo-summary{
  height: 13px;
}
.foot-mark-box{}
.foot-mark{
  width: 175px;
}
.foot-content{
  grid-column: 1 / -1;
  margin: 30px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: $C-footer-text;
}
.foot-contact{
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 0 8px;
}
.foot-contact-row{}
.foot-contact-item{}
.foot-link{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 0 12px;
}
.foot-link-item{
  color: $C-footer-link;
  &.terms{
    color: $C-blue-400;
    font-weight: bold;
  }
}
.foot-copy{}
//quick
.quick-box{
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  right: 52px;
  bottom: 100px;
  z-index: 50;
}
.quick-btn{}
.quick-ico{}


// Loading
.loading_indicator {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* OLD - IE 19- (buggy but mostly works) */
  display: flex; /* iOS 7.1+, android 4.4+ */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &.type-hide {
    display: none;
  }
}