@charset "UTF-8";

// layout Size
$G-max-width: 1920px;
$G-content-width: 992px;

// Base Font Style
$F-base: 'Noto Sans KR',sans-seif;
$F-print: 'Nanum Myeongjo', 'Open Sans','Nanum Barun Gothic',sans-seif;
$F-base-size: 10px;

// Color
$C-white: #ffffff;
$C-black: #000000;
$C-active-400: #0068b6;
$C-active-200: #2b8fe3;
$C-apply: #2d91df;
$C-warning: #ff2c51;

$C-gray-100: #e9ebee;
$C-gray-200: #c5c8ce;
$C-gray-300: #707070;
$C-gray-400: #7f8a97;
$C-gray-500: #646f7c;
$C-gray-700: #4a4a4a;
$C-gray-800: #374553;
$C-gray-900: #353535;

$C-blue-400: #3e9fe3;

$C-footer-bg: #374553;
$C-footer-text: #7f8a97;
$C-footer-link: #c5c8ce;

//etc
$G-aside-width: 320px;
$G-aside-width-m: 280px;
$G-aside-mobile-height: 64px;

$media-mobile: 800px;

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin ellipsis-multi($param) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $param;
  overflow: hidden;
}
@mixin overlay{
  overflow: auto;
  overflow: overlay;
}
@mixin mobile {
  @media screen and (max-width: #{$media-mobile}) {
    @content;
  }
}
@mixin section-board {
  border-radius: 10px;
  background: $C-white;
}