@charset "UTF-8";

.section-box{
  display: flex;
  flex-direction: column;
  gap: 64px;
  min-width: 0;
}
.section-item{}
.section-header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0 20px;
  min-width: 0;
  margin: 0 0 16px;
}
.section-title{
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $C-gray-800;
}
.section-more{
  min-height: 20px;
  padding: 0 17px 0 0;
  background: url(~@/assets/images/ico_more_arrow@3x.png) no-repeat 100% 8px/12px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.section-content{}
.card-box{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 16px;
}
.card{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 35px 24px 26px;
  text-align: left;
  @include section-board;
}
.card-thumb{
  flex: none;
  width: 70px;
  height: 77px;
  background: url(~@/assets/images/ico_card_thumb@3x.png) no-repeat 50% 100%/contain;
  &.type-notification{
    background-image: url(~@/assets/images/ico_card_thumb_notification@3x.png);
  }
}
.card-text{
  flex: 1;
  padding: 4px 0 0;
}
.card-title{
  max-height: 48px;
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $C-gray-800;
  @include ellipsis-multi(2);
}
.card-date{
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-400;
}
.sheet{
  padding: 40px 24px;
  @include section-board;
}
.sheet-tab-box{
  overflow-x: auto;
  position: relative;
  margin: 0 0 5px;
  padding: 0 0 15px;
  &:after{
    content: '';
    display: block;
    position: sticky;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 1px;
    background: $C-gray-200;
  }
}
.sheet-tab-inner{
  display: flex;
  gap: 0 20px;
}
.sheet-tab-btn{
  flex: none;
  position: relative;
  min-height: 27px;
  padding: 0 0 4px;
  font-size: 18px;
  font-weight: 700;
  color: $C-gray-200;
  box-sizing: content-box;
  &.type-on{
    color: $C-active-200;
    &:after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 1px;
      background: $C-active-200;
    }
  }
}
.sheet-sort-box{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 0 0 40px;
}
.sheet-sort-btn{
  min-width: 88px;
  min-height: 32px;
  border: 1px solid $C-gray-200;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-200;
  &.type-on{
    border-color: $C-active-200;
    color: $C-active-200;
  }
}
.sheet-kv-box{}
.sheet-kv-item{
  display: flex;
  padding: 48px 0;
  border-bottom: 1px solid #c4c4c4;
  &:first-of-type{
    padding-top: 0;
  }
  &:last-of-type{
    padding-bottom: 24px;
    border-bottom: 0;
  }
}
.sheet-key{
  flex: none;
  width: 138px;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  color: $C-gray-800;
}
.sheet-val{
  flex: 1;
  &.type-summary{
    font-size: 16px;
    line-height: 24px;
    color: $C-gray-700;
  }
}
.sheet-val-inner{
  &.type-sub{
    font-size: 16px;
    line-height: 21px;
    color: $C-gray-800;
  }
  &.type-desc{
    font-size: 14px;
    line-height: 18px;
    color: $C-gray-400;
  }
  &.type-title{
    margin: 0 0 4px;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: $C-gray-800;
  }
  &.type-dd{
    font-size: 16px;
    line-height: 24px;
    color: #646f7c;
  }
  &.type-etc{
    font-size: 14px;
    line-height: 20px;
    color: #646f7c;
  }
}
.sheet-val-dl-box{
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.sheet-val-dl{
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.sheet-val-dt{
  flex: none;
  width: 56px;
}
.sheet-val-dd{
  flex: 1;
}
.ico-process{}
.article-box{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.article{
  padding: 24px;
  @include section-board;
}
.article-header{
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 20px;
}
.article-title{
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $C-gray-800;
}
.article-content{}
.list-box{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.list-item{
  display: inline-flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
  padding: 0 16px;
  border: 1px solid $C-gray-100;
  border-radius: 5px;
  background: $C-white;
  font-size: 14px;
  line-height: 20px;
}
.list-key{
  color: $C-gray-400;
}
.list-val{
  font-weight: 700;
  color: $C-gray-800;
  &.type-yes{
    color: $C-active-200;
  }
  &.type-no{
    color: #ff2c51;
  }
}
.modal-wrap{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba($C-black, 0.5);
  }
}
.modal-inner{
  position: fixed;
  left: 50%;
  top: 50%;
  width: calc(100% - 100px);
  max-height: calc(100% - 100px);
  padding: 20px 20px 48px;
  border-radius: 10px;
  background: $C-white;
  transform: translate(-50%, -50%);
  @include overlay;
  &.type-regist{
    max-width: 656px;
  }
  &.type-certi{
    max-width: 835px;
  }
  &.type-video{
    width: 900px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .model-header{
      padding-top: 20px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  &.type-quiz{
    max-width: 656px;
  }
  &.type-login{
    max-width: 488px;
  }
  &.type-survey{
    max-width: 656px;
  }
}
.model-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px;
  border-bottom: 1px solid $C-gray-200;
}
.modal-title{
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $C-gray-800;
}
.modal-info{
  flex: none;
  display: flex;
  gap: 56px;
  min-width: 0;
}
.modal-control-box{
  display: flex;
  align-items: center;
  min-width: 0;
}
.modal-control-btn{
  position: relative;
  width: 16px;
  height: 8px;
  padding: 8px;
  box-sizing: content-box;
}
.modal-control-btn-inner{
  position: absolute;
  left: 50%;
  top: 50%;
  &.type-prev{
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &.type-next{
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}
.modal-control-ico{
  width: 16px;
  height: 8px;
}
.modal-control-step{
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-800;
}
.modal-close-btn{}
.modal-close-ico{}
.modal-content{}
.modal-video-box{
  width: 900px;
  height: 510px;
  background: $C-gray-500;
}
.regist-box{}
.regist-row{
  display: flex;
  align-items: center;
  min-height: 55px;
  border-bottom: 1px solid $C-gray-200;
  box-sizing: content-box;
}
.regist-kv{
  flex: 1;
  display: flex;
  align-items: center;
  min-height: inherit;
}
.regist-key,
.regist-val{
  padding: 12px 0;
  font-size: 14px;
  line-height: 20px;
}
.regist-key{
  flex: none;
  width: 145px;
  padding-left: 14px;
  color: $C-gray-400;
}
.regist-val{
  flex: 1;
  min-width: 0;
  color: $C-gray-800;
  &.type-text{
    padding-left: 15px;
  }
}

.step-box{
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 0;
  &.type-modal{
    display: none;
  }
}
.quiz-box{
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 0 0;
}
.quiz-item{}
.quiz-title{
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.quiz-answer-box{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.quiz-answer-item{
  display: flex;
  gap: 8px;
  min-width: 0;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
  .inp{
    flex: none;
  }
  .lab{
    &.red{
      color: $C-warning;
    }
  }
}

.step{
  border-radius: 10px;
  background: $C-white;
}
.step-content{
  &.type-btn{
    width: 100%;
    text-align: left;
  }
}
.step-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  min-width: 0;
  padding: 16px 24px;
  &.type-sub{
    padding-left: 49px;
  }
  &:after{
    .step-content.type-btn &{
      content: "";
      flex: none;
      display: block;
      width: 25px;
      height: 12px;
      background: url(~@/assets/images/ico_arrow.svg) no-repeat 50% 50%/contain;
    }
    .step-content.type-btn.type-extend &{
      transform: rotate(180deg);
    }
  }
}
.step-text{
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
}
.step-title{
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $C-gray-800;
  .step.type-finished &{
    color: $C-active-200;
  }
}
.step-status{
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-400;
}
.step-sub-box{
  display: none;
  .step-content.type-extend + &{
    display: block;
  }
}
.step-sub-row{
  border-top: 1px solid $C-gray-200;
}
.step-sub-content{
  overflow: hidden;
}
.step-sub-title{
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-800;
  .step-sub-row.type-finished &{
    color: $C-active-200;
  }
}
.step-sub-date{
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-400;
}
.step-btn-box{
  flex: none;
  display: flex;
  gap: 8px;
  min-width: 0;
}
.step-sub-btn{}
.step-sub-img{
  .ico-color{
    fill: $C-active-200;
  }
  .type-finished &.type-video{
    .ico-color{
      fill: $C-gray-400;
    }
  }
}
.sort-box{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 0;
  font-size: 14px;
  color: $C-gray-800;
}
.data-container{
  padding: 48px 24px 43px;
  @include section-board;
}
.data-box{}
.data-inner{}
.data-row{
  display: flex;
  min-width: 0;
}
.data-cell{
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 63px;
  padding: 5px;
  border-bottom: 1px solid $C-gray-200;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
  text-align: center;
  &.type-th{
    border-top: 1px solid $C-gray-200;
    color: $C-gray-400;
  }
  &.type-num{width: 80px;}
  &.type-title{flex: 1;}
  &.type-file{width: 80px;}
  &.type-writer{width: 80px;}
  &.type-date{width: 120px;}
  &.type-view{ width: 80px;}
  &.type-answer{ width: 80px;}
  &.type-yn{ width: 80px;}
  &.type-detail{
    order: 20;
    display: none;
    width: 100%;
    margin: 16px 0 0;
    font-size: 16px;
    line-height: 24px;
    color: $C-gray-800;
    text-align: left;
  }
}
.data-cell-inner{
  &.type-content{
    padding: 40px 0;
    border-bottom: 1px solid $C-gray-200;
    &:first-of-type{
      border-top: 1px solid $C-gray-200;
    }
  }
  &.type-file{
    display: flex;
    align-items: center;
    min-width: 0;
    min-height: 30px;
    padding: 16px 0 0;
    box-sizing: content-box;
  }
}
.data-cell-inner-btn{
  display: flex;
  gap: 8px;
  line-height: 20px;
  text-align: left;
}
.data-cell-inner-ico{
  flex: none;
}
.inp-box{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 0;
  &.type-notice{
    display: none;
  }
  &.type-tos{
    column-gap: 48px;
    font-size: 14px;
    line-height: 20px;
    color: $C-gray-800;
    .inp-item{
      align-items: flex-start;
      gap: 8px;
    }
  }
  &.type-join{
    gap: 12px 32px;
    .inp-item{
      gap: 8px;
    }
  }
  &.type-survey{
    gap: 8px 16px;
    .inp-item{
      gap: 8px;
    }
  }
}
.inp-item{
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
}
.tf-box{
  &.type-sort{
    flex: 1;
    min-width: 148px;
    max-width: 232px;
  }
}
.paging-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 43px 0 0;
}
.paging-btn{
  padding: 5px;
  &.type-first{}
  &.type-prev{}
  &.type-num{
    font-size: 16px;
    line-height: 24px;
    color: $C-gray-400;
    &.type-on{
      color: $C-active-200;
    }
  }
  &.type-next{
    transform: rotate(180deg);
  }
  &.type-last{
    transform: rotate(180deg);
  }
}
.paging-ico{
  height: 16px;
}
.detail-container{
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 24px;
  @include section-board;
  &.type-notice{
    gap: 24px;
    .detail-key{
      width: 107px;
    }
  }
  &.type-qna{
    .detail-key{
      width: 115px;
    }
  }
  &.type-qna-write{
    .detail-key{
      width: 99px;
    }
  }
}
.detail-box{}
.detail-row{
  display: flex;
  min-width: 0;
}
.detail-kv{
  flex: 6;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  min-height: 63px;
  border-bottom: 1px solid $C-gray-200;
  box-sizing: content-box;
  .detail-row:first-of-type &{
    border-top: 1px solid $C-gray-200;
  }
  &.type-dense{
    flex: 2;
    .detail-key{
      width: auto;
      padding-right: 24px;
    }
  }
}
.detail-key,
.detail-val{
  padding: 15px 0;
  font-size: 16px;
  line-height: 24px;
}
.detail-key{
  flex: none;
  display: flex;
  align-items: center;
  gap: 7px;
  min-width: 0;
  padding-left: 24px;
  color: $C-gray-400;
  &.type-prev,
  &.type-next{
    &:after{
      content: "";
      width: 20px;
      height: 16px;
      background: url(~@/assets/images/ico_more_arrow@3x.png) no-repeat 50% 50%/contain;
    }
  }
  &.type-prev{
    &:after{
      transform: rotate(-90deg);
    }
  }
  &.type-next{
    &:after{
      transform: rotate(90deg);
    }
  }
}
.detail-val{
  flex: 1;
  color: $C-gray-800;
  &.type-content{
    display: grid;
    align-items: center;
    min-width: 0;
    min-height: 220px;
  }
}
.tos-container{
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 24px;
  border-radius: 10px;
  background: $C-white;
}
.tos-box{
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.tos-item{}
.mobile-check{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  min-height: 65px;
  border-top: 1px solid $C-gray-200;
  border-bottom: 1px solid $C-gray-200;
}
.mobile-check-key{
  flex: 1;
  padding: 10px 17px;
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-800;
}
.tos-title{
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: $C-gray-800;
}
.tos-content{
  overflow: auto;
  max-height: 240px;
  margin: 0 0 16px;
  padding: 20px 20px 40px;
  border: 1px solid $C-gray-200;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.join-container{
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 24px;
  border-radius: 10px;
  background: $C-white;
}
.join-box{}
.join-row{
  display: flex;
  align-items: center;
  min-width: 0;
  min-height: 63px;
  border-bottom: 1px solid $C-gray-200;
  box-sizing: content-box;
  &:first-of-type{
    border-top: 1px solid $C-gray-200;
  }
}
.join-key,
.join-val{
  padding: 10px 0;
}
.join-key{
  flex: none;
  width: 148px;
  padding-left: 14px;
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-400;
  &.type-required{
    &:before{
      content: "*";
      color: $C-active-200;
    }
  }
}
.join-val{
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 4px 16px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.join-tel-box{
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 4px;
}
.login-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0 0;
}
.login-logo-box{
  display: none;
  width: 100%;
  max-width: 190px;
  margin: 0 auto;
  padding: 53px 0;
}
.login-box{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 0 4px;
}
.login-row{
  display: flex;
  align-items: center;
  min-width: 0;
  padding: 0 16px;
}
.login-key{
  flex: none;
  width: 91px;
  font-size: 16px;
  line-height: 24px;
  color: $C-gray-400;
}
.login-val{
  flex: 1;
  input, select {
    font-size: 16px;
  }
}
.login-link-box{
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 36px 32px;
  padding: 0 16px;
}
.login-link{
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.upload{
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
}
.survey-container{
  padding: 48px 0 0;
}
.survey-preface{}
.preface-title{
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 700;
  color: $C-gray-800;
}
.preface-text{
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-500;
}
.preface-eg-box{
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  gap: 1px;
  border: 1px solid $C-gray-200;
  background: $C-gray-200;
}
.preface-eg-item{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 56px;
  background: $C-white;
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-400;
  text-align: center;
}
.survey-box{
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 32px 0 64px;
}
.survey-item{
  font-size: 14px;
  line-height: 20px;
  color: $C-gray-800;
}
.survey-question{
  margin: 0 0 20px;
}
.survey-answer{

}

.filebox{
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  &.type-void{
    .filebox-delete{
      display: none;
    }
  }
  .filebox-inner{
    position: relative;
  }
  .filebox_1_upload-name{
    width: 176px;
    height: 26px;
    padding: 0 26px 0 .5em;
    background: #efefef;
  }
  .filebox_1_label{
    display: flex;
    flex: none;
    align-items: center;
    min-width: 69px;
    min-height: 26px;
    padding: 0 1em;
    margin-right: 0.5em;
    border-radius: .25em;
    background: #5d6b85;
    font-size: 13px;
    color: $C-white;
    cursor: pointer;
  }
  .upload-hidden{
    position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    opacity: .01;
    clip: rect(0,0,0,0);
  }
  .filebox-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $C-black;
    font-size: 0;
    color: transparent;
    text-indent: -9999px;
    transform: translate(0, -50%);
    &:before,
    &:after{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 75%;
      height: 2px;
      background: $C-white;
    }
    &:before{
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
.agency-box{
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.agency-item{
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: .25em .5em;
  border-radius: 5px;
  background: #efefef;
  line-height: 1.2;
  &:after{
    content: "삭제";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($C-black, .6);
    font-size: 13px;
    color: $C-white;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
  }
  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

.video-react-progress-control.video-react-control{
  visibility: hidden;
}

.can-control{
  .video-react-progress-control.video-react-control{
    visibility: visible;
  }
}

.certificate-print-box{
  //width: 700px;
  //padding-left: 50px;
  background: $C-white;
  -webkit-print-color-adjust: exact;
}
.popup-print-box{
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 210mm;
  min-height: 297mm;
  border: 1px solid $C-gray-900;
  font-family: $F-print !important;
  background: $C-white;
  &.type-certificate{
    //width: 600px;
    //height: 1011.18px;
    width: 210mm;
    height: 297mm;
    padding: 150px 30px 120px;
    background: $C-white no-repeat 50% 50%/100% 100%;
    -webkit-print-color-adjust: exact;
    border: 0;
    &.type-1{
      background-image: url(~@/assets/images/certi_bg1.png);
    }
    &.type-2{
      background-image: url(~@/assets/images/certi_bg2.png);
    }
  }

  .print-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: $C-gray-100;
    font-size: 30px;
    text-align: center;
  }
  .print-info{
    //min-height: 202px;
  }
  .print-info-tbl{
    table-layout: fixed;
    width: 100%;
    th, td{
      padding: 5px;
      border: 1px solid $C-gray-900;
      line-height: 24px;
      box-sizing: content-box;
      &:first-of-type{border-left:0;}
      &:last-of-type{border-right:0;}
    }
    th{
      &:not(.type-wide) {
        background: $C-gray-100;
      }
      &.type-wide{
        height: 48px;
        padding-right: 15px;
        padding-left: 15px;
        font-size: 24px;
        text-align: left;
        @include ellipsis;
      }
    }
    td{
      height: 72px;
    }
    &.type-data{
      th, td{
        font-size: 14px;
        line-height: 1.5;
      }
      td{
        height: auto;
      }
    }
  }
  .print-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    //min-height: 589px;
    padding: 30px;
    &:empty{
      padding: 0;
    }
  }
  .print-box{
    flex: 1;
    display: grid;
    gap: 60px 0;
  }
  .print-item{
    display: flex;
    flex-direction: column;
    gap: 30px 0;
  }
  .print-item-title{

  }
  .print-item-li{}
  .print-text{
    margin: 95px 0 30px;
    font-size: 20px;
    text-align: center;
  }
  .print-date-box{
    display: flex;
    justify-content: flex-end;
    gap: 0 10px;
  }
  .print-date-item{}
  .print-name-box{
    display: flex;
    flex-direction: column;
    //display: grid;
    gap: 10px;
    //height: 131px;
    padding: 20px 0;
    border-top: 1px solid $C-gray-900;
    font-size: 20px;
  }
  .print-name-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 10px;
    width: 500px;
    height: 40px;
    margin: 0 auto;
  }
  .print-name-key{
    flex: none;
  }
  .print-name-val{
    flex: 1;
    display: flex;
    align-items: center;
  }
  .print-name-val-text{
    flex: 1;
  }
  .print-name-val-sign-box{
    flex: none;
    position: relative;
  }
  .print-name-val-sign-text{
    font-size: 16px;
    color: $C-gray-700;
    &.on{
      color: $C-gray-400;
    }
  }
  .print-name-val-sign-img{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 40px;
    transform: translate(-50%,-50%);
  }

  .print-name-tbl{
    min-width: auto;
    width: 600px;
    margin: 0 auto;
    th, td{
      height: 40px;
    }
    th{
      padding-right: .3em;
      text-align: right;
    }
    td{
      text-align: left;
    }
  }
}

.certificate{
  &-box{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: $F-print;
    @include word-break;
  }
  &-nm{
    margin: 0 0 90px;
    padding: 0 0 0 35px;
    font-size: 14px;
  }
  &-title{
    display: flex;
    justify-content: center;
    gap: 0 80px;
    margin: 0 0 80px;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
  }
  &-kv{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 0 15px;
    font-weight: 700;
    &-row{
      display: grid;
      grid-template-columns: 145px 1fr;
    }
  }
  &-key{
    padding: 0 20px;
    font-size: 0;
    text-align: justify;
    &::before,
    &::after{
      content: "";
      display: inline-block;
      width: 100%;
      line-height: 0;
    }
    &-inner{
      font-size: 16px;
      line-height: 1.5;
      &:after{
        content: ":";
        margin: 0 0 0 .5em;
      }
    }
  }
  &-val{
    font-size: 16px;
    line-height: 1.5;
  }
  &-desc{
    margin: 0 0 100px;
    padding: 0 32px;
    font-size: 22px;
    font-weight: 700;
    line-height: 2;
    text-indent: 1em;
  }
  &-date{
    display: flex;
    justify-content: center;
    gap: 0 1em;
    margin: 0 0 130px;
    font-size: 22px;
    font-weight: 700;
  }
  &-sign-box{
    text-align: center;
  }
  &-sign-inner{
    display: inline-block;
    position: relative;
  }
  &-sign-text{
    display: inline-flex;
    gap: 0 14px;
    position: relative;
    z-index: 1;
    font-size: 32px;
    font-weight: 700;
  }
  &-sign-img{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.certi_sample{
  width: 100%;
  text-align: center;
}
.certi_sample_cont{
  display: inline-block;
}
.certi_sample_item{
  text-align: center;
  padding: 5px;
  width: 300px;
  float: left;
  margin: 10px;
}

.video-react{
  .video-react-time-control, .video-react-time-control-track{
    font-size: 1.1em;
    line-height: 2.9em;
  }
  .video-react-time-control-track{
    padding: 0 1em;
    color: #a4a4a4;
    &.on{
      color: $C-white;
    }
    &.hidden{
      display: none;
    }
  }
}

.join_content_strong{
  font-size: 1.2em;
  text-decoration: underline;
}

.join_content_strong2{
  font-size: 1.2em;
  color: blue;
  font-weight: bold;
  text-decoration: underline;
}
