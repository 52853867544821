@charset "UTF-8";

.type-mobile {
  //common
  .btn-box{
    &.type-login{
      margin-top: 0;
    }
  }
  .btn{
    &.type-large{
      flex: 1;
      min-width: auto;
      max-width: 152px;
    }
    &.type-wide{
      flex: 1;
      max-width: none;
    }
    &.type-sort{
      min-width: 48px;
    }
    &.type-join{
      flex: 1;
      max-width: none;
    }
    &.type-login{
      max-width: none;
    }
  }
  .tf{
    &.type-large{
      width: 100%;
    }
  }
  //layout
  .aside{
    position: fixed;
    top: 0;
    z-index: 100;
    &.type-on{
      &:before{
        content: "";
      }
      .aside-mobile{
        top: -$G-aside-mobile-height - 6px;
      }
      .aside-content{
        left: 0;
      }
    }
  }
  .aside-mobile{
    top: 0;
  }
  .aside-content{
    position: fixed;
    left: -$G-aside-width-m - 1px;
    top: 0;
    bottom: 0;
    width: $G-aside-width-m;
    padding-left: 40px;
    transition: .2s;
  }
  .aside-close-btn{
    display: block;
  }
  .content{
    padding: $G-aside-mobile-height 0 0;
    &.type-main{
      .content-inner{
        padding-top: 52px;
      }
      .section-header{
        margin-bottom: 10px;
      }
    }
  }
  .content-inner{
    width: auto;
    margin: 0;
  }
  .foot-inner{
    width: auto;
  }
  .foot-header{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  }
  .foot-logo-box{
    flex-direction: column;
    align-items: flex-start;
  }
  .foot-logo-summary-box{
    gap: 5px;
  }
  .foot-logo-summary-item{
    aspect-ratio: 195 / 13;
    width: 100%;
    max-height: 13px;
    &.type-1{
      margin-bottom: 3px;
    }
  }
  .foot-logo-summary{
    height: 100%;
  }
  .foot-contact{
    gap: 8px;
  }
  .foot-contact-row{
    display: flex;
    flex-direction: column;
    gap: 4px;
    &.type-dense{
      gap: 0;
    }
  }
  .foot-mark-box{
    order: 100;
    margin-top: 32px;
  }
  .quick-box{
    gap: 8px;
    right: 20px;
    bottom: 80px;
  }
  .quick-ico{
    width: 40px;
  }
  //style
  .article-header{
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 16px;
  }
  .card-box{
    grid-template-columns: 1fr;
  }
  .card{
    padding-right: 32px;
  }
  .sheet-kv-item{
    flex-direction: column;
    gap: 4px;
    &:last-of-type{
      padding-bottom: 8px;
    }
  }
  .sheet-key{
    width: auto;
  }
  .sheet-val-dl-box{
    margin-top: 12px;
  }
  .sheet-val-dl{
    flex-direction: column;
    gap: 8px;
  }
  //modal
  .modal-inner{
    left: 0;
    top: 0;
    width: 100% !important;
    max-width: none !important;
    height: 100%;
    max-height: none;
    padding-top: 18px;
    border-radius: 0;
    transform: none;
    &.type-fixed{
      display: flex;
      flex-direction: column;
      .modal-header{
        flex: none;
      }
      .modal-content{
        flex: 1;
      }
    }
  }
  .modal-info{
    gap: 16px;
  }
  .modal-video-box{
    width: 100%;
    flex: none;
    height: auto;
    aspect-ratio: 16 / 9;
  }
  .modal-content{}
  .step-box{
    &.type-modal{
      display: flex;
      padding: 20px;
      background: $C-gray-100;
    }
  }
  .regist-row{
    flex-wrap: wrap;
  }
  .regist-kv{
    flex: none;
    width: 100%;
    &:not(:first-of-type) {
      border-top: 1px solid $C-gray-200;
    }
  }
  .regist-key{
    width: 130px;
    padding-left: 0;
  }
  .step-title{
    @include ellipsis;
  }
  .step-inner{
    &.type-sub{
      padding-left: 36px;
    }
  }
  .step-sub-title{
    @include ellipsis;
  }
  .opt{
    &.type-notice{
      display: none;
    }
  }
  .inp-box{
    &.type-notice{
      display: flex;
    }
    &.type-tos{
      justify-content: space-between;
      gap: 8px;
    }
    &.type-join{
      flex-direction: column;
    }
  }
  .data-container{
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .data-row{
    border-bottom: 1px solid $C-gray-200;
    padding: 16px 24px;
    &.type-thead{
      display: none !important;
    }
    &.type-extend{
      .data-cell{
        &.type-detail{
          display: block;
        }
        &.type-title{
          button{
            &:after{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &.type-notice{
      display: flex;
      flex-wrap: wrap;
    }
    &.type-qna{
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
  .data-cell{
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto;
    padding: 0;
    border-bottom: 0;
    &.type-num{
      display: none;
    }
    &.type-title{
      grid-column: 1 / -1;
      order: 0;
      flex: none;
      width: 100%;
      margin: 0 0 8px;
      font-weight: 700;
      button{
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 24px;
        &:after{
          content: "";
          flex: none;
          display: block;
          width: 20px;
          height: 10px;
          background: url(~@/assets/images/ico_arrow.svg) no-repeat 50% 50%/contain;
        }
      }
    }
    &.type-file{
      order: 10;
      width: auto;
    }
    &.type-border{
      &:before{
        content: "|";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 14px;
      }
    }
    &.type-writer,
    &.type-date,
    &.type-view,
    &.type-file,
    &.type-answer,
    &.type-yn{
      width: auto;
    }
    &.type-writer{order: 1}
    &.type-date{order: 2}
    &.type-view{order: 3}
    &.type-file{order: 4}
    &.type-answer{order: 5}
    &.type-yn{order: 6}
    &.type-detail{
      grid-column: 1 / -1;
    }
    &.type-qna{
      &.type-writer:before{
        content: "작성자:";
      }
      &.type-answer:before{
        content: "답변자:";
      }
    }
  }
  .detail-container{
    gap: 24px;
    padding: 0;
    background: none;
    &.type-notice{
      .detail-key{
        width: 107px;
      }
    }
    &.type-qna{
      .detail-key{
        width: 67px;
      }
    }
    &.type-qna-write{
      .detail-key{
        width: 67px;
      }
    }
  }
  .detail-box{
    padding: 48px 24px;
    @include section-board;
  }
  .detail-row{
    flex-direction: column;
  }
  .detail-kv{
    &.type-file{
      align-items: flex-start;
      .detail-key{
        line-height: 32px;
      }
    }
  }
  .detail-key{
    padding-left: 0;
    padding-right: 0 !important;
  }
  .mobile-check{
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    min-height: 105px;
    padding: 10px 0;
  }
  .mobile-check-key{
    flex: none;
    padding: 0;
  }
  .join-container{
    gap: 24px;
    padding: 0;
    background: none;
  }
  .join-box{
    padding: 48px 24px;
    @include section-board;
  }
  .join-row{
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 20px 0;
  }
  .join-key,
  .join-val{
    padding: 0;
  }
  .join-val{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .login-container{
    padding: 0;
  }
  .login-logo-box{
    display: block;
  }
  .login-box{
    gap: 16px;
  }
  .login-row{
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
  .login-link-box{
    flex-direction: column;
    justify-content: center;
    padding: 36px 0;
  }
  .upload{
    gap: 8px;
  }
  .preface-eg-item{
    min-height: 96px;
    &.type-title{
      flex: none;
      width: 100%;
      min-height: 24px;
    }
  }
}